import React from "react"
import RSelect from "react-select"

// Note: in browser view double dropdown arrows are displayed.
const Select = ({ options, onChange, value, className, bootstrapped }) => {
  const opts = options.map(opt => ({ value: opt.value, label: opt.name }))
  const actualValue = opts.find(option => option.value === value)
  return (
    <RSelect
      className={`${!bootstrapped && "custom-select"} w-40 flex flex-col justify-center ${className}`}
      value={actualValue}
      onChange={v => onChange(v.value)}
      options={opts}
    />
  )
}

export default Select
